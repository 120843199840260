.rdp-caption_label {
  font-size: 1rem;
}

.rdp-head_cell {
  text-transform: none;
  font-size: 1rem;
  font-weight: normal;
  text-align: center !important;
}

.rdp-overlay_wrapper {
  position: relative;
}

.rdp-overlay {
  position: absolute;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
}
